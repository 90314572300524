import React, { useEffect, useState } from "react";

import "./products.css";
import { modalServiceSubject } from "../../services/modal.service";
import { useNavigate, useParams } from "react-router";
import { ProductDetail } from "./detail";
import Modal from "react-modal";
import {
  CurrencyFormat,
  dateTimeFormat,
  gtmSelectItem,
  gtmViewItem,
  gtmViewItemList,
} from "../../services/misc.functions";
import { isMobile } from "../../App";
import oyunlar from "../../assets/icons/oyunlar.svg";
import cekilis from "../../assets/icons/cekilis.svg";

import { auth } from "../../store/auth.store";
import paths from "../../paths";
import { ApiService } from "../../services/http.service";
import { env } from "../../constants/global.vars";
import { RaffleSlider } from "../raffles/widgets/raffle.slider";
import gift from "../../assets/img/home_icon_products.png";
import eSansWhite from "../../assets/icons/e-sans-white.svg";

let requestStatusMap: any = {
  0: {
    class: "warning",
    label: "Beklemede",
  },
  1: {
    class: "success",
    label: "Onaylandı",
  },
  2: {
    class: "danger",
    label: "Stokta Yok",
  },
  3: {
    class: "info",
    label: "Gönderildi",
  },
};

const tabs = [
  { value: "oduller", label: "ÖDÜLLER" },
  { value: "taleplerim", label: "TALEPLERİM" },
];

export function Products() {
  const [dataList, setDataList] = useState<any[]>([]);
  const [requestList, setRequestList] = useState<any[]>([]);
  const [selectedTab, setSelectedTab] = useState<any>(tabs[0]);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const api = new ApiService();
    getRequests();

    api.start("get", env.accounting_api + "/product/api/get-products/", null, false).then((res: any) => {
      setDataList(res.data);
      gtmViewItemList(res.data, "Ödüller");
    });
  }, []);

  const getRequests = () => {
    const api = new ApiService();
    api.start("get", env.accounting_api + "/product/api/list-prize-request/", null, true).then((res: any) => {
      if (res && res.status) {
        setRequestList(res.data);
      }
    });
  };

  const handleSelect = (e: any) => {
    getRequests();
    if (e.target.id) {
      setSelectedTab(tabs[e.target.id]);
    }
  };

  if (params?.id && dataList && dataList.length > 0) {
    let product: any = dataList.find((el: any) => el.id === Number(params.id));
    return <ProductDetail product={product}></ProductDetail>;
  }

  return (
    <div className="row gap-3">
      {!isMobile && <RaffleSlider />}

      {!isMobile && (
        <div className="page-widget p-0">
          <svg style={{ display: "none" }}>
            <defs>
              <path id="tabshape" d="M80,60C34,53.5,64.417,0,0,0v60H80z"></path>
            </defs>
          </svg>
          <div className={"tab-scroll"}>
            {/* Tab Header */}
            <ul className="nav nav-tabs">
              {tabs.map(({ value, label }, index) => {
                return (
                  <li
                    key={`raffle-tabs-${index}`}
                    className={`nav-item raffle-item ${selectedTab.value === value ? "active" : ""}`}
                    onClick={handleSelect}
                  >
                    <svg className="left" viewBox="0 0 80 60" preserveAspectRatio="none">
                      <use xlinkHref="#tabshape"></use>
                    </svg>
                    <a className="nav-link" id={`${index}`}>
                      {value === "biletlerim" ? (
                        <img
                          src={"../../assets/icons/esans-icon.png"}
                          width={18}
                          height={18}
                          style={{ marginRight: "5px" }}
                        />
                      ) : (
                        <></>
                      )}
                      {label}
                    </a>
                    <svg className="right" viewBox="0 0 80 60" preserveAspectRatio="none">
                      <use xlinkHref="#tabshape"></use>
                    </svg>
                  </li>
                );
              })}
            </ul>
          </div>
          {/* Tab Content */}

          <div className="row p-2 row-gap-2">
            {selectedTab.value === "oduller" && (
              <>
                {dataList &&
                  dataList.map((el: any, key: number) => {
                    return (
                      <div key={`${key}`} className="col-6 col-md-4 col-lg-3">
                        <Product product={el}></Product>
                      </div>
                    );
                  })}
              </>
            )}
            {selectedTab.value === "taleplerim" && (
              <>
                <div className={"prize-request"}>
                  {requestList.length == 0 && (
                    <>
                      <div className={"w-100"}>
                        <p className={"text-center"}>Henüz bir talebiniz bulunmamaktadır.</p>
                        <div className={"d-flex justify-content-center"}>
                          <button
                            className="mx-auto btn btn-primary w-50 mt-5"
                            onClick={() => {
                              setSelectedTab(0);
                            }}
                          >
                            Ödülleri İncele
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  {requestList.map((el: any) => {
                    return (
                      <div className="row item" key={"request-item-" + el.id}>
                        <div className="col-2 d-flex justify-content-center align-items-center border-dashed">
                          <img className="img-thumbnail" src={`${env.cdn_host}/media/${el.product__ProductPicture}`} />
                        </div>
                        <div className="col-8 d-flex flex-column justify-content-center border-dashed">
                          <h3>{el.name}</h3>
                          <span className="d-block">Talep Tarihi: {dateTimeFormat(el.create_date)}</span>
                          <span className="d-block">Talep Adedi: {Number(el.amount).toFixed(0)}</span>
                        </div>

                        <div className="col-2 d-flex justify-content-center align-items-center">
                          <div className={`status ${requestStatusMap[el.status].class} mb-2`}>
                            {requestStatusMap[el.status].label}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {isMobile && params.slug !== "sepet" && (
        <div className={"d-flex justify-content-center align-items-center mt-2"}>
          <img src={eSansWhite} height={25} style={{ marginRight: "0.5rem" }} />
          <h2 className={"text-white mb-0"}>Şans Oyunları</h2>
        </div>
      )}

      {isMobile && (
        <div className={"col-12"}>
          <div className={"mobile-tab-widget"}>
            <div className={"row"}>
              <div className={"col-5"}>
                <div
                  className="raffle-tab-item border"
                  onClick={() => {
                    navigate(paths.games.url);
                  }}
                >
                  <img alt={"dijital-oyunlar"} src={oyunlar} />
                  <div>
                    <h2>
                      DİJİTAL
                      <br />
                      OYUNLAR
                    </h2>
                  </div>
                </div>
              </div>

              <div className={"col-5"}>
                <div
                  className="raffle-tab-item border"
                  onClick={() => {
                    navigate(paths.raffles.url);
                  }}
                >
                  <img alt={"cekilis"} src={cekilis} />
                  <div>
                    <h2>
                      EŞYA
                      <br />
                      PİYANGOSU
                    </h2>
                  </div>
                </div>
              </div>
              <div className={"col-2 p-0"}>
                <div
                  className="row p-0 m-0 d-flex flex-column align-items-center justify-content-center fs-8 fw-bold border rounded-4 text-primary"
                  style={{ height: "70px", backgroundColor: "#adb2ff47" }}
                >
                  <img alt={"oduller"} src={gift} style={{ width: "38px" }} />
                  {/* <span className={""}>YENİ</span> */}
                  <div>
                    <span>ÖDÜLLER</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={"border-bottom-dotted"}></div>
            <div className={"row"}>
              <div className={"col-6"}>
                <div
                  onClick={() => {
                    setSelectedTab(tabs[0]);
                  }}
                  className={`draw-tab-item ${selectedTab.value === "oduller" ? "selected" : ""}`}
                >
                  ÖDÜLLER
                </div>
              </div>
              <div className={"col-6"}>
                <div
                  onClick={() => {
                    getRequests();
                    setSelectedTab(tabs[1]);
                  }}
                  className={`draw-tab-item ${selectedTab.value === "taleplerim" ? "selected" : ""}`}
                >
                  TALEPLERİM
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isMobile && (
        <div className="page-widget row row-gap-4 m-0 py-2">
          {selectedTab.value === "oduller" && (
            <>
              {dataList &&
                dataList.map((el: any, key: number) => {
                  return (
                    <div key={`${key}`} className="col-6 col-md-4 col-lg-3">
                      <Product product={el}></Product>
                    </div>
                  );
                })}
            </>
          )}
          {selectedTab.value === "taleplerim" && (
            <div className={"prize-request"}>
              {requestList.length == 0 && (
                <>
                  <div className={"w-100"}>
                    <p className={"text-center"}>Henüz bir talebiniz bulunmamaktadır.</p>
                    <div className={"d-flex justify-content-center"}>
                      <button
                        className="mx-auto btn btn-primary w-50 mt-5"
                        onClick={() => {
                          setSelectedTab(0);
                        }}
                      >
                        Ödülleri İncele
                      </button>
                    </div>
                  </div>
                </>
              )}
              {requestList.map((el: any) => {
                return (
                  <div className="row item" key={"request-item-" + el.id}>
                    <div className="col-2 d-flex justify-content-center align-items-center border-dashed">
                      <img className="img-thumbnail" src={`${env.cdn_host}/media/${el.product__ProductPicture}`} />
                    </div>
                    <div className="col-8 d-flex flex-column justify-content-center border-dashed">
                      <h3>{el.name}</h3>
                      <span className="d-block">Talep Tarihi: {dateTimeFormat(el.create_date)}</span>
                      <span className="d-block">Talep Adedi: {Number(el.amount).toFixed(0)}</span>
                    </div>

                    <div className="col-2 d-flex justify-content-center align-items-center">
                      <div className={`status ${requestStatusMap[el.status].class}`}>
                        {requestStatusMap[el.status].label}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

let errorMessagesMap: any = {
  member_error: "Üye girişi yapmalısınız.",
  product_error: "Ürün bulunamadı.",
  balance_point_available_error: "Yetersiz Bakiye",
};

export function Product(props: any) {
  const [added, setAdded] = useState<any>(false);
  const product = props.product;
  const [itemModal, setItemModal] = useState<any>(null);
  const navigate = useNavigate();

  const createRequest = (product: any) => {
    const api = new ApiService();
    if (auth.member) {
      if (added && added > 0) {
        api
          .start(
            "post",
            env.accounting_api + "/product/api/create-prize-request/",
            {
              product_id: product.id,
              amount: added,
            },
            true
          )
          .then((res: any) => {
            if (res && res.status) {
              setAdded(false);
              modalServiceSubject.next({
                case: "success",
                title: "Talep Aldık!",
                content: `<p class="text-center">Talebiniz oluşturuldu. Talep kontrolü sonrasında sizleri eposta ve sms yolu ile bilgilendireceğiz. 
               Eğer eposta yada SMS almıyorsanız lütfen iletişim ayarlarınızı kontrol edin ve operatöreünüzden gerekli izinleri talep edin.
              </p>`,
                confirm: {
                  cancel: {
                    label: "Tamam",
                    class: "btn-success w-100",
                  },
                },
              });
            } else {
              modalServiceSubject.next({
                case: "danger",
                title: errorMessagesMap[res.error] || "Bakiyeniz Yetersiz 😞",
                content: `<p class="text-center">
                ${res.message}
                
                <br />Ödül kataloğundan sadece oyunlarda kazandığınız NP puanlarınız ile ödül talep edebilirsiniz.<br /><br />
                Şans oyunlarımızdan oynayarak NP Puan kazanabilir ve ödülünüzü talep edebilirsiniz.  
               
              </p>`,
                confirm: {
                  cancel: {
                    label: "Tamam",
                    class: "btn-danger w-100",
                  },
                },
              });
            }
          });
      }
    } else {
      if (isMobile) {
        navigate(paths.landing.url);
      } else {
        modalServiceSubject.next({
          class: "success",
          title: "Üye Girişi",
          content: `<p>Satınalma yapabilmeniz için önce oturum açmanız gerekli. Üye değilseniz hemen üye olabilir yada giriş yaparak alışverişinizi tamamlaya bilirsiniz.</p>`,
          confirm: {
            sure: {
              label: "Giriş Yap",
              class: "btn btn-success",
              action: () => {
                navigate(paths.login.url);
              },
            },
            cancel: {
              label: "Üye Ol",
              class: "btn btn-info",
              action: () => {
                navigate(paths.register.url);
              },
            },
          },
        });
      }
    }
  };
  return (
    <>
      <div className="product-widget">
        <div
          className="head pointer"
          onClick={() => {
            setItemModal(product);
            gtmSelectItem([product], "Ödüller");
          }}
        >
          <h2>{product.name}</h2>
        </div>
        <div
          className="body pointer"
          onClick={() => {
            setItemModal(product);
            gtmSelectItem([product], "Ödüller");
          }}
        >
          <img src={env.cdn_host + "/media/" + product.ProductPicture} alt={product.code} />
        </div>
        <div className="foot">
          {added !== false && (
            <div className="add">
              <div className="input w-50">
                <i
                  onClick={() => {
                    if (added > 1) {
                      setAdded(added - 1);
                    } else {
                      setAdded(false);
                    }
                  }}
                >
                  -
                </i>
                <input
                  disabled={true}
                  className="no-arrows"
                  type="number"
                  value={added || ""}
                  onChange={(e: any) => {
                    setAdded(e.target.value);
                  }}
                  onBlur={(e: any) => {
                    if (e.target.value === "") {
                      setAdded(false);
                    }
                  }}
                  max={100}
                  step={1}
                  min={1}
                />
                <i
                  onClick={() => {
                    setAdded(added + 1);
                  }}
                >
                  +
                </i>
              </div>
              <div className="price w-50">
                <span>{CurrencyFormat(product.price, 0)}NP</span>
              </div>
            </div>
          )}

          <button
            className={added !== false ? "added" : ""}
            onClick={() => {
              if (added === false) {
                setAdded(1);
              } else {
                modalServiceSubject.next({
                  case: "info",
                  title: "Talep Onayı",
                  content: `<p><b>${CurrencyFormat(
                    product.price * added,
                    0
                  )} NP</b> karşılığında <b>${added} adet</b> ${product.name} talebini onaylıyor musunuz?</p>`,
                  confirm: {
                    cancel: {
                      class: "btn btn-primary",
                      label: "Vazgeç",
                    },
                    sure: {
                      class: "btn btn-warning",
                      label: "Onayla",
                      action: () => {
                        createRequest(product);
                      },
                    },
                  },
                });
              }
            }}
          >
            Talep Oluştur <span className="price">{CurrencyFormat(product.price, 0)} NP</span>
          </button>
        </div>
      </div>

      <Modal
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            maxWidth: "calc(100vw - 80px)",
            width: "460px",
            maxHeight: "calc(100vh - 70px)",
            transform: "translate(-50%, -50%)",
            borderRadius: "1rem",
            overflow: "hidden",
          },
          overlay: {
            zIndex: 2,
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            backdropFilter: "blur(10px)",
          },
        }}
        isOpen={itemModal !== null}
        className={"default product-detail-modal"}
        onRequestClose={() => {
          setItemModal(null);
        }}
        onAfterOpen={() => {
          gtmViewItem([itemModal], "Ödüller");
        }}
        contentLabel=""
      >
        {itemModal && (
          <>
            <div className="modal-header">
              <h3>{itemModal.name}</h3>
            </div>
            <div className="modal-body">
              <img className="picture" src={env.cdn_host + "/media/" + itemModal.ProductPicture} alt={product.code} />
              <div
                className="info"
                dangerouslySetInnerHTML={{ __html: itemModal.description.replaceAll("\n", "<br/>") }}
              ></div>
            </div>
            <div className="modal-footer mb-0">
              <button
                type="button"
                onClick={() => {
                  setItemModal(null);
                }}
                className="btn btn-warning w-100 mt-2"
              >
                Kapat
              </button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
}
