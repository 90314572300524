// Genel kullanım için description ve keywords değerlerini değiştirebilirsiniz.

import { HorseStats } from "./pages/jockeys/horseStats";

const description =
  "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.";
const keywords =
  "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala";

const paths: any = {
  home: {
    url: "/",
    title: "4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  login: {
    url: "/giris-yap",
    title: "GİRİŞ YAP - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  logout: {
    url: "/cikis-yap",
    title: "ÇIKIŞ YAP - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  memberInfo: {
    url: "/uyelik-bilgilerim",
    title: "UYELİK BİLGİLERİM - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  notificationInfo: {
    url: "/bildirim-tercihlerim",
    title: "UYELİK BİLGİLERİM - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  memberTickets: {
    url: "/biletlerim",
    title: "BİLETLERİM - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  memberSavedTickets: {
    url: "/biletlerim/kayitli",
    title: "BİLETLERİM - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  memberBankAccounts: {
    url: "/banka-hesaplarim",
    title: "BANKA HESAPLARIM - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  memberTransactions: {
    url: "/hesap-hareketlerim",
    title: "HESAP HAREKETLERİM - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  cookieSettings: {
    url: "/cerez-ayarlari",
    title: "HESAP HAREKETLERİM - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  withdraw: {
    url: "/para-cek",
    title: "PARA ÇEK - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  deposit: {
    url: "/para-yatir",
    title: "PARA YATIR - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  support: {
    url: "/yardim-at-yarisi-bahis-tjk",
    title:
      "YARDIM, OYUN KURALLARI, NASIL TJK KUPON YAPARIM - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  announcement: {
    url: "/tjk-duyurulari-haberler",
    title:
      "DUYURULAR, HABERLER,  KOŞMAZ AT, YASAKLI JOKEYLER - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  agreement: {
    url: "/sozlesmeler",
    title: "SÖZLEŞMELER - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  notifications: {
    url: "/bildirimlerim",
    title: "BİLDİRİMLERİM - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  flatpage: {
    url: "/fp",
    title: " 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  stream: {
    url: "/tjk-tv-atyarisi-izle-canli-yayin",
    title:
      "TJK TV, AT YARIŞI İZLE CANLI YAYIN, - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  tipsterCoupons: {
    url: "/hazir-kuponlar-yorumcular-at-yarisi-tahminleri",
    title: "HAZIR KUPONLAR - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "At yarışı tahminci ve yorumcularının Hazır kuponları. Hazır 6lı kuponlar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "6lı, altılı kuponlar, hazır kuponlar, at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  tipsterComments: {
    url: "/yorumlar-yorumcular-at-yarisi-tahminleri",
    title: "HAZIR KUPONLAR - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "At yarışı tahminci ve yorumcularının Hazır kuponları. Hazır 6lı kuponlar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "6lı, altılı kuponlar, hazır kuponlar, at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  register: {
    url: "/uye-ol",
    title: "HEMEN ÜYE OL - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  resetPassword: {
    url: "/sifre-sifirla",
    title: "ŞİFREMİ UNUTTUM - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  changePassword: {
    url: "/sifre-degistir",
    title: "ŞİFRE DEĞİŞTİR - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  verifyEmail: {
    url: "/eposta-dogrula",
    title: "EPOSTA DOĞRULA - 4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  betting: {
    url: "/tjk-at-yarisi-bulteni-bahis-yap",
    title: "BAHİS YAP - AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  program: {
    url: "/tjk-at-yarisi-programi",
    title: "4NALA.COM, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  results: {
    url: "/tjk-at-yarisi-sonuclari",
    title: "AT YARIŞI SONUÇLARI, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  campaigns: {
    url: "/kampanyalar",
    title: "AT YARIŞI SONUÇLARI, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  enhancements: {
    url: "/yenilikler",
    title: "AT YARIŞI SONUÇLARI, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  reportedJokeys: {
    url: "/tjk-raporlu-cezalı-jokeyler",
    title: "AT YARIŞI SONUÇLARI, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  horseStats: {
    url: "/tjk-kosmayan-atlar-jokey-degisikligi",
    title: "AT YARIŞI SONUÇLARI, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  raffles: {
    url: "/sans-oyunlari/esya-piyangosu",
    title: "AT YARIŞI SONUÇLARI, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  products: {
    url: "/sans-oyunlari/oduller",
    title: "AT YARIŞI SONUÇLARI, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  giftTicket: {
    url: "/hediye-biletler",
    title: "AT YARIŞI SONUÇLARI, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },
  statistics: {
    url: "/istatistikler",
    title: "AT YARIŞI SONUÇLARI, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description,
    keywords,
  },

  //   Oyunlar
  games: {
    url: "/sans-oyunlari/dijital-oyunlar",
    title: "AT YARIŞI SONUÇLARI, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
    description:
      "4nala.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
    keywords:
      "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, 4nala",
  },
  fireballs: {
    url: "/sans-oyunlari/ates-toplari",
    title: "Ateş Topları | Şans Oyunları | Ateş Topları Oyna | 4Nala",
    description,
    keywords,
  },
  fruitTowers: {
    url: "/sans-oyunlari/meyve-kulesi",
    title: "Meyve Kulesi | Şans Oyunları | Meyve Kulesi Oyna | 4Nala",
    description,
    keywords,
  },
  turboMines: {
    url: "/sans-oyunlari/isiltili-taslar",
    title: "Işıltılı Taşlar | Şans Oyunları | Işıltılı Taşlar Oyna | 4Nala",
    description,
    keywords,
  },
  crashX: {
    url: "/sans-oyunlari/galaksi-turu",
    title: "Galaksi Turu | Şans Oyunları | Galaksi Turu Oyna | 4Nala",
    description,
    keywords,
  },
  turboPlinko: {
    url: "/sans-oyunlari/super-plinko",
    title: "Süper Plinko | Şans Oyunları | Süper Plinko Oyna | 4Nala",
    description,
    keywords,
  },
  hamsta: {
    url: "/sans-oyunlari/kunduzun-macerasi",
    title: "Kunduzun Macerası | Şans Oyunları | Kunduzun Macerası Oyna | 4Nala",
    description,
    keywords,
  },
  superWheel: {
    url: "/sans-oyunlari/super-carkifelek",
    title: "Çarkıfelek | Şans Oyunları | Çarkıfelek Oyna | 4Nala",
    description,
    keywords,
  },
  rockPaperScissors: {
    url: "/sans-oyunlari/tas-kagit-makas",
    title: "Taş Kağıt Makas | Şans Oyunları | Taş Kağıt Makas Oyna | 4Nala",
    description,
    keywords,
  },
  zeplin: {
    url: "/sans-oyunlari/zeplin",
    title: "Zeplin | Şans Oyunları | Zeplin Oyna | 4Nala",
    description,
    keywords,
  },
  superBingo: {
    url: "/sans-oyunlari/super-tombala",
    title: "Süper Tombala | Şans Oyunları | Süper Tombala Oyna | 4Nala",
    description,
    keywords,
  },
  scratch: {
    url: "/sans-oyunlari/kazi-kazan",
    title: "Kazı Kazan | Şans Oyunları | Kazı Kazan Oyna | 4Nala",
    description,
    keywords,
  },
  scratchMyCards: {
    url: "/sans-oyunlari/kazi-kazan/kartlarim",
    title: "Kazı Kazan | Şans Oyunları | Kazı Kazan Oyna | 4Nala",
    description,
    keywords,
  },
  scartchRules: {
    url: "/sans-oyunlari/kazi-kazan/kurallar",
    title: "Kazı Kazan | Şans Oyunları | Kazı Kazan Oyna | 4Nala",
    description,
    keywords,
  },
};

export default paths;
