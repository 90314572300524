import { useForceUpdate } from "../../../services/misc.functions";
import React, { useEffect, useState } from "react";
import {
  addAllToBetSlip,
  addToTJKBetSlip,
  betSlipActionSubject,
  isAdded,
  isAddedAll,
  tjkBetSlipOptions,
} from "../../../services/betslip.service";
import _ from "lodash";
import { getPrettyFormatJewelry, getPrettyFormatLastSix } from "../../../services/bulletin.service";
import jokeyIcon from "../../../assets/img/jokey.png";
import iconInfo from "../../../assets/feather/info.svg";
import lockIcon from "../../../assets/feather/lock.svg";
import iconPlayCircle from "../../../assets/feather/play-circle.svg";
import iconFather from "../../../assets/feather/father-at-yarisi.svg";
import iconExit from "../../../assets/feather/x-circle.svg";
import iconSettings from "../../../assets/feather/settings.svg";
import statisticsIcon from "../../../assets/icons/trend.png";
import { courseColors, defaultColumns, selectedColumns } from "../../../store/bulletin.store";
import Modal from "react-modal";
import { MobileBetslip } from "../../../shared/betslip/betslip";
import { isMobile } from "../../../App";
import { modalServiceSubject } from "../../../services/modal.service";
import { useNavigate } from "react-router";
import paths from "../../../paths";
import { Link, useLocation } from "react-router-dom";
import { Storage } from "../../../services/localstorege.service";

let mobileFooterHeight: number = 58;

export function GetStablemateIcon({ horse }: any) {
  return (
    <>
      {horse.stablemate && Number(horse.stablemate) === 1 ? (
        <>
          <img alt={"iconFather"} src={iconFather} style={{ marginTop: -3, filter: "brightness(0)" }} />
        </>
      ) : (
        <></>
      )}

      {horse.stablemate && Number(horse.stablemate) === 2 ? (
        <>
          <img
            alt={"iconFather"}
            src={iconFather}
            style={{
              marginTop: -3,
              filter: "invert(21%) sepia(100%) saturate(7414%) hue-rotate(359deg) brightness(94%) contrast(117%)",
            }}
          />
        </>
      ) : (
        <></>
      )}

      {horse.stablemate && Number(horse.stablemate) === 3 ? (
        <>
          <img alt={"iconFather"} src={iconFather} style={{ marginTop: -3 }} />
        </>
      ) : (
        <></>
      )}

      {horse.stablemate && Number(horse.stablemate) === 4 ? (
        <>
          <img
            alt={"iconFather"}
            src={iconFather}
            style={{
              marginTop: -3,
              filter: "invert(21%) sepia(100%) saturate(7414%) hue-rotate(216deg) brightness(100%) contrast(117%)",
            }}
          />
        </>
      ) : (
        <></>
      )}

      {horse.stablemate && Number(horse.stablemate) === 5 ? (
        <>
          <img
            alt={"iconFather"}
            src={iconFather}
            style={{
              marginTop: -3,
              filter: "invert(21%) sepia(100%) saturate(7414%) hue-rotate(130deg) brightness(100%) contrast(117%)",
            }}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export function BettingDataTable(prop: any) {
  const selectBettingColumn = prop.selectBettingColumn;
  const [showTrainingVideo, setShowTrainingVideo] = useState<any>(false);
  const [showHorseStat, setShowHorseStat] = useState<any>(false);
  const [showJockeyStat, setShowJockeyStat] = useState<any>(false);
  const [openCustomizeModal, setOpenCustomizeModal] = useState<any>(false);
  const parser = new DOMParser();
  const query = new URLSearchParams(useLocation().search);
  const sort = query.get("sort");
  const navigate = useNavigate();

  const forceUpdate = useForceUpdate();
  useEffect(() => {
    mobileFooterHeight = document.getElementById("mobile-footer-menu")?.offsetHeight || 58;

    let betSlipActionSubject_subscription = betSlipActionSubject.subscribe((res: any) => {
      forceUpdate();
    });
    return () => {
      betSlipActionSubject_subscription.unsubscribe();
    };
  }, []);

  const scrollHandler = (event: any) => {
    const betSlip: any = document.getElementById("mobile-foot-betslip");
    const horseRows: any = document.getElementsByClassName("mobile-betting-table");

    try {
      if (betSlip && horseRows && horseRows.length > 0) {
        if (
          window.scrollY >
          horseRows[horseRows.length - 1].offsetTop - betSlip.offsetTop + horseRows[horseRows.length - 1].offsetHeight
        ) {
          betSlip.classList.remove("sticky");
          betSlip.parentNode.classList.add("pb-2");
        }

        if (betSlip.offsetTop - window.scrollY > window.outerHeight - betSlip.offsetHeight - mobileFooterHeight) {
          betSlip.classList.add("sticky");
          betSlip.parentNode.classList.remove("pb-2");
          betSlip.parentNode.style.paddingBottom = betSlip.offsetHeight + "px";
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  if (!tjkBetSlipOptions.bettingSubType || !tjkBetSlipOptions.column) {
    return <></>;
  }

  const runs: any = tjkBetSlipOptions.hippodrome.runs.filter((el: any) =>
    tjkBetSlipOptions.bettingSubType.label
      .split("-")
      .map((el: any) => Number(el))
      .includes(Number(el.no))
  );

  let run: any = {};
  if (tjkBetSlipOptions.bettingSubType.label.split("-").length > 1) {
    run = runs.find(
      (el: any) =>
        Number(el.no) === Number(tjkBetSlipOptions.bettingSubType.label.split("-")[tjkBetSlipOptions.column - 1])
    );
    if (tjkBetSlipOptions.bettingType.id === 18) {
    }
  } else {
    run = runs.find((el: any) => Number(el.no) === Number(tjkBetSlipOptions.bettingSubType.id));
  }

  let minGanyan = _.orderBy(run?.horses, ["ganyan"], ["asc"])[0];

  const getContentResults = (url: any) => {
    fetch(url)
      .then((response) => response.text())
      .then((html) => {
        let ob = document.getElementById("id_iframe_results");
        if (ob) {
          const doc: any = parser.parseFromString(html, "text/html");
          let element: any = doc.getElementById(url.split("#")[1]);
          if (element) {
            console.log(element, "element");
          }
          // ob.innerHTML = html
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  const getVideoSource = (videoUrl: any) => {
    fetch(videoUrl)
      .then((response) => response.text())
      .then((html) => {
        let finds: any = Array.from(html.matchAll(/var _vvy1(.*?)mp4/g));
        console.log(finds[0][0].split('"')[1]);

        setShowTrainingVideo({ training_video: finds[0][0].split('"')[1] });
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  const getContentJockey = () => {
    let url =
      "https://www.tjk.org/TR/YarisSever/Query/Grouped/JokeyIstatistikleri?1=1&QueryParameter_JokeyId=" +
      showJockeyStat.jokey.code;
    fetch(url)
      .then((response) => response.text())
      .then((html) => {
        let ob = document.getElementById("id_iframe");
        if (ob) {
          const doc = parser.parseFromString(html, "text/html");
          let queryTable = doc.getElementById("queryTable");
          if (queryTable) {
            queryTable.classList.add("jockeyStats");
            let aElements: any = queryTable.getElementsByTagName("a");
            if (aElements) {
              for (let a of aElements) {
                if (a.getAttribute("href")) {
                  a.removeAttribute("href");
                }
              }
            }
            ob.innerHTML = queryTable.outerHTML;
          }
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  const slugify = (str: any) => {
    return String(str)
      .normalize("NFKD") // split accented characters into their base characters and diacritical marks
      .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
      .trim() // trim leading or trailing whitespace
      .toLowerCase() // convert to lowercase
      .replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
      .replace(/\s+/g, "-") // replace spaces with hyphens
      .replace(/-+/g, "-"); // remove consecutive hyphens
  };

  const getContentHorse = () => {
    let url =
      "https://www.tjk.org/TR/YarisSever/Query/ConnectedPage/AtKosuBilgileri?1=1&QueryParameter_AtId=" +
      showHorseStat.code;
    fetch(url)
      .then((response) => response.text())
      .then((html) => {
        let ob = document.getElementById("id_iframe");
        if (ob) {
          const doc = parser.parseFromString(html, "text/html");
          let tab1 = doc.getElementById("tabs-1");
          if (tab1) {
            let aElements: any = tab1.getElementsByTagName("a");
            if (aElements) {
              for (let a of aElements) {
                a.removeAttribute("href");
              }
            }
            ob.innerHTML = tab1.innerHTML;

            let queryTable = doc.getElementById("queryTable") as HTMLTableElement;

            let myRows: any = [];

            // @ts-ignore
            let headers = [...queryTable.getElementsByTagName("th")];
            // @ts-ignore
            let rows = [...queryTable.getElementsByTagName("tr")].forEach((el: any, index: number) => {
              let cells = el.getElementsByTagName("td");
              myRows[index] = {};
              [...cells].forEach((cell: any, cellIndex: number) => {
                myRows[index][slugify(headers[cellIndex].innerText)] = cell.innerText.replace(/^\s+|\s+$/g, "");
              });
            });
            let myObj: any = {};
            myObj.myrows = myRows;

            console.log(myObj);

            if (queryTable) {
              queryTable.classList.add("horseStats");
              // @ts-ignore
              let aElements: any = [...queryTable.getElementsByTagName("a")];
              if (aElements) {
                aElements.forEach((a: any, i: number) => {
                  if (a.getAttribute("href")) {
                    if (a.getAttribute("href").indexOf("YarisVideoAt") !== -1) {
                      let originUrl = a.getAttribute("href");
                      // getVideoSource('https://www.tjk.org/' + originUrl)
                      a.removeAttribute("href", "#");
                      // a.setAttribute('target', '_blank');

                      a.addEventListener("click", () => {
                        getVideoSource("https://www.tjk.org/" + originUrl);
                      });
                    } else if (a.getAttribute("href").indexOf("fotoftp") !== -1) {
                      a.setAttribute("target", "_blank");
                    } else if (a.getAttribute("href").indexOf("GunlukYarisSonuclari") !== -1) {
                      let originUrl = a.getAttribute("href");
                      a.removeAttribute("href", "#");

                      console.log(i, aElements[i].innerText, aElements[i + 1].innerText);

                      // a.addEventListener('click', () => {
                      //   navigate(`${paths.results.url}/${aElements[i].innerText.toLowerCase()}/${run.no}`)
                      // });

                      // a.addEventListener('click', () => {
                      //   getContentResults('https://www.tjk.org' + originUrl);
                      // });
                    } else {
                      a.removeAttribute("href");
                    }
                  }
                  a.removeAttribute("onClick");
                });
              }
              ob.appendChild(queryTable);
            }
          }
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  return (
    <>
      <div className="ps-2 pt-0 pt-lg-2 pb-2 pe-2 ">
        {!isMobile && (
          <div className="betting-run-info ">
            <div className={"row align-items-center"}>
              <div className={"col-6 d-flex align-items-center pe-0"}>
                <div className={""}>
                  <span
                    style={{
                      borderRadius: "10px",
                      border: "1px solid",
                      padding: "0.25rem",
                      background: "#efeff4",
                    }}
                  >
                    {run?.time}
                  </span>
                </div>
                <div className={"d-flex flex-column"}>
                  <div>
                    <span>{run?.no}.Koşu</span>
                    <span className="course" style={{ backgroundColor: courseColors[run?.course] }}>
                      {run?.course}
                    </span>
                    <span>{run?.distance}m</span>
                    <span>{run?.group}</span>
                    {run?.type_detail ? (
                      <>
                        <span>{run?.type_detail}</span>
                      </>
                    ) : null}
                    {/*{run?.important_race_name.length > 0 ?*/}
                    {/*    <span>{run?.important_race_name}</span> : null}*/}
                    {run?.gender ? (
                      <>
                        <span>{run?.gender}</span>
                      </>
                    ) : null}
                    {run?.gender_detail ? (
                      <>
                        <span>{run?.gender_detail}</span>
                      </>
                    ) : null}
                  </div>
                  <div>
                    <span className="fw-normal fs-8">
                      {[...Array.from(new Set(run?.bets.map((e: any) => e.name)))].join(", ")}
                    </span>
                  </div>
                </div>
              </div>
              <div className={"col-6"}>
                <div className={"d-flex align-items-center justify-content-between"}>
                  <div>
                    <Link
                      className={"btn btn-secondary fw-semibold fs-7 ms-1 w-100"}
                      to={`${paths.statistics.url}/${
                        tjkBetSlipOptions.hippodrome.date.split("T")[0]
                      }/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}`}
                    >
                      İSTATİSTİKLER
                    </Link>
                  </div>
                  <div>
                    <Link
                      className={"btn btn-secondary fw-semibold fs-7 ms-1 w-100"}
                      to={`${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}${
                        tjkBetSlipOptions.hippodrome._id
                      }/yorumlar`}
                    >
                      YORUMLAR
                    </Link>
                  </div>
                  <div>
                    <Link
                      className={"btn btn-secondary fw-semibold fs-7 ms-1 w-100"}
                      to={`${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}${
                        tjkBetSlipOptions.hippodrome._id
                      }/tjk-tv`}
                    >
                      TJK TV
                    </Link>
                  </div>
                  {/*   <div>
                            <Link className={"btn btn-secondary fw-semibold fs-7 ms-1 w-100"}
                                  to={`${paths.tipsterCoupons.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}`}>
                                HAZIR KUPONLAR
                            </Link>
                        </div>*/}
                  {/* <div>
                  <select
                    style={{ padding: "0.25rem 0.5rem", width: "25px" }}
                    onChange={(e: any) => {
                      navigate(
                        `${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}${
                          tjkBetSlipOptions.hippodrome._id
                        }?sort=${e.target.value}`
                      );
                    }}
                  >
                    <option value={""}></option>
                    <option value={"no"}>No'ya Göre</option>
                    <option value={"agf_ratio1"}>AGF'ye Göre</option>
                    <option value={"ganyan"}>Ganyan'a Göre</option>
                    <option value={"start"}>Kulvar'a Göre</option>
                  </select>
                </div> */}

                  <div className="dropdown dropdown-without-arrow">
                    <button
                      className={`btn btn-sm btn-secondary dropdown-toggle ms-1`}
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {!isMobile && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="me-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                        </svg>
                      )}
                    </button>
                    <ul className="dropdown-menu bg-white">
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            navigate(
                              `${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}${
                                tjkBetSlipOptions.hippodrome._id
                              }?sort=no`
                            );
                          }}
                        >
                          No'ya Göre
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            navigate(
                              `${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}${
                                tjkBetSlipOptions.hippodrome._id
                              }?sort=agf_ratio1`
                            );
                          }}
                        >
                          AGF'ye Göre
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            navigate(
                              `${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}${
                                tjkBetSlipOptions.hippodrome._id
                              }?sort=ganyan`
                            );
                          }}
                        >
                          Ganyan'a Göre
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            navigate(
                              `${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}${
                                tjkBetSlipOptions.hippodrome._id
                              }?sort=start`
                            );
                          }}
                        >
                          Kulvar'a Göre
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div>
                    <img
                      src={iconSettings}
                      alt=""
                      className={"me-2 ms-2 mt-0"}
                      width={23}
                      height={23}
                      onClick={() => {
                        setOpenCustomizeModal(true);
                      }}
                    />
                    <img
                      src={iconInfo}
                      alt=""
                      className={"ms-2 mt-0"}
                      width={23}
                      height={23}
                      onClick={() => {
                        modalServiceSubject.next({
                          title: "İkramiye Bilgisi",
                          width: "400px",
                          content: (
                            <>
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th className="text-end">İkramiyeler</th>
                                    <th className="text-end">Primler</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {run.prize_1 && (
                                    <tr>
                                      <td>1</td>
                                      <td className="text-end">
                                        {run.prize_1}
                                        {run.currency}
                                      </td>
                                      <td className="text-end">
                                        {run.breeder_prize_1} {run.currency}
                                      </td>
                                    </tr>
                                  )}
                                  {run.prize_2 && (
                                    <tr>
                                      <td>2</td>
                                      <td className="text-end">
                                        {run.prize_2}
                                        {run.currency}
                                      </td>
                                      <td className="text-end">
                                        {run.breeder_prize_2} {run.currency}
                                      </td>
                                    </tr>
                                  )}
                                  {run.prize_3 && (
                                    <tr>
                                      <td>3</td>
                                      <td className="text-end">
                                        {run.prize_3}
                                        {run.currency}
                                      </td>
                                      <td className="text-end">
                                        {run.breeder_prize_3} {run.currency}
                                      </td>
                                    </tr>
                                  )}
                                  {run.prize_4 && (
                                    <tr>
                                      <td>4</td>
                                      <td className="text-end">
                                        {run.prize_4}
                                        {run.currency}
                                      </td>
                                      <td className="text-end">
                                        {run.breeder_prize_4} {run.currency}
                                      </td>
                                    </tr>
                                  )}
                                  {run.prize_5 && (
                                    <tr>
                                      <td>5</td>
                                      <td className="text-end">
                                        {run.prize_5}
                                        {run.currency}
                                      </td>
                                      <td className="text-end">
                                        {run.breeder_prize_5} {run.currency}
                                      </td>
                                    </tr>
                                  )}
                                  {run.prize_6 && (
                                    <tr>
                                      <td>6</td>
                                      <td className="text-end">
                                        {run.prize_6}
                                        {run.currency}
                                      </td>
                                      <td className="text-end">
                                        {run.breeder_prize_6} {run.currency}
                                      </td>
                                    </tr>
                                  )}
                                  {run.prize_7 && (
                                    <tr>
                                      <td>7</td>
                                      <td className="text-end">
                                        {run.prize_7}
                                        {run.currency}
                                      </td>
                                      <td className="text-end">
                                        {run.breeder_prize_7} {run.currency}
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </>
                          ),
                          confirm: {
                            cancel: {
                              label: "Kapat",
                            },
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isMobile && (
          <div
            className={"d-flex justify-content-between"}
            style={{ lineHeight: "14px", paddingLeft: 5, paddingTop: 5, paddingBottom: 5 }}
          >
            <div className={"d-flex "}>
              <div className={"d-flex justify-content-center align-items-center"}>
                <span className="fw-semibold fs-5">{run?.time}</span>
              </div>
              <div className={"ms-3"}>
                <p className="fs-7 mb-0">
                  {run?.no}.KOŞU{" "}
                  <span className="betting-run-info-mobile px-2" style={{ backgroundColor: courseColors[run?.course] }}>
                    {run?.distance}m {run?.course}
                  </span>
                </p>
                <p className="me-4 fs-7 mb-0 mt-1">
                  {run?.group} {run?.type_detail || ""} {run?.gender || ""} {run?.gender_detail || ""}
                </p>
                <span>{run?.important_race_name || ""}</span>
              </div>
            </div>
            <div className={"d-flex justify-content-end align-items-center"}>
              <Link
                to={`${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}${
                  tjkBetSlipOptions.hippodrome._id
                }/tjk-tv`}
                className={"btn btn-outline-secondary fw-semibold fs-7 select-all-horse"}
                style={{ minWidth: "70px" }}
              >
                <img
                  alt={"videoIcon"}
                  src={
                    "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTkuNzExIDIwLS41OTUtLjI5OGMtLjA1LS4wMjUtLjA3NS0uMDI1LS4xLS4wNS00LjIxOS0yLjIzMy02LjEzLTQuNTQxLTYuOS01LjczM0MxLjEgMTIuMzgxIDEgMTAuNTQ0IDEgMTAuMzQ2VjEuMjEybC45OTMtLjI0OEM2LjM4Ni0uMDc4IDguOTY3LS4wMjggOS42ODYuMDIxYy43Mi0uMDUgMy4zMDEtLjEgNy42OTQuOTQzbC45OTMuMjQ4djkuMTM0YzAgLjIyMy0uMTI0IDIuMDM1LTEuMTE3IDMuNTk4LS43NyAxLjE5Mi0yLjY4IDMuNS02LjkyNCA1LjczM2wtLjYyLjMyM1pNMS41MjEgMS42MXY4LjcxYzAgLjA3NS4xIDEuODYyIDEuMDQzIDMuMzI2Ljc0NCAxLjE0MiAyLjU4IDMuMzc2IDYuNyA1LjU2LjAyNS4wMjUuMDUuMDI1LjA3NS4wNWwuMzcyLjE3My4zNzMtLjE5OGM0LjE0NC0yLjE4NCA1Ljk4LTQuNDE4IDYuNzI1LTUuNTYuOTQzLTEuNDY0IDEuMDQzLTMuMjUgMS4wNDMtMy4zMjVWMS42MzRsLS41OTYtLjE0OUMxMi45MTMuNDQzIDEwLjM4MS40OTMgOS43MTEuNTQyaC0uMDQ5Yy0uNjctLjA1LTMuMjAyLS4wOTktNy41NDUuOTQzbC0uNTk2LjEyNVoiIGZpbGw9IiMyQTFEMzAiLz48cGF0aCBkPSJNMTAuNzU0IDUuNThjLS4yNDgtLjA1LS4zOTctLjA1LS4zOTctLjA1LjE0OSAwIC4yNzMuMDI2LjM5Ny4wNVoiIGZpbGw9IiMyQTFEMzAiLz48cGF0aCBkPSJNOS43IDEuMTE5Yy42MjgtLjA0NCAyLjg4Mi0uMDg4IDYuNzE4LjgzM2wuODY3LjIydjcuMDczSDkuN2guMDExTDkuNyAxLjExOVoiIGZpbGw9IiNGRjAwMjAiLz48cGF0aCBkPSJNMi45ODIgOWgtLjg2N3YxLjAzN2MwIC4xOTcuMTA4IDEuNzk5Ljk3NSAzLjE4MS42NzIgMS4wNTMgMi4zNCAzLjA5MyA2LjA0NiA1LjA2OGwuNTQyLjI4NVY5SDIuOTgyWiIgZmlsbD0iIzJBMUQzMCIvPjwvc3ZnPg=="
                  }
                  width={18}
                  className={"me-1"}
                />{" "}
                TV
              </Link>
              <img
                src={iconInfo}
                alt=""
                className={"ms-1"}
                width={22}
                height={22}
                onClick={() => {
                  modalServiceSubject.next({
                    title: "İkramiye Bilgisi",
                    width: "400px",
                    content: (
                      <>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th className="text-end">İkramiyeler</th>
                              <th className="text-end">Primler</th>
                            </tr>
                          </thead>
                          <tbody>
                            {run?.prize_1 && (
                              <tr>
                                <td>1</td>
                                <td className="text-end">
                                  {run?.prize_1}
                                  {run?.currency}
                                </td>
                                <td className="text-end">
                                  {run?.breeder_prize_1} {run?.currency}
                                </td>
                              </tr>
                            )}
                            {run?.prize_2 && (
                              <tr>
                                <td>2</td>
                                <td className="text-end">
                                  {run?.prize_2}
                                  {run?.currency}
                                </td>
                                <td className="text-end">
                                  {run?.breeder_prize_2} {run?.currency}
                                </td>
                              </tr>
                            )}
                            {run?.prize_3 && (
                              <tr>
                                <td>3</td>
                                <td className="text-end">
                                  {run?.prize_3}
                                  {run?.currency}
                                </td>
                                <td className="text-end">
                                  {run?.breeder_prize_3} {run?.currency}
                                </td>
                              </tr>
                            )}
                            {run?.prize_4 && (
                              <tr>
                                <td>4</td>
                                <td className="text-end">
                                  {run?.prize_4}
                                  {run?.currency}
                                </td>
                                <td className="text-end">
                                  {run?.breeder_prize_4} {run?.currency}
                                </td>
                              </tr>
                            )}
                            {run?.prize_5 && (
                              <tr>
                                <td>5</td>
                                <td className="text-end">
                                  {run?.prize_5}
                                  {run?.currency}
                                </td>
                                <td className="text-end">
                                  {run?.breeder_prize_5} {run?.currency}
                                </td>
                              </tr>
                            )}
                            {run?.prize_6 && (
                              <tr>
                                <td>6</td>
                                <td className="text-end">
                                  {run?.prize_6}
                                  {run?.currency}
                                </td>
                                <td className="text-end">
                                  {run?.breeder_prize_6} {run?.currency}
                                </td>
                              </tr>
                            )}
                            {run?.prize_7 && (
                              <tr>
                                <td>7</td>
                                <td className="text-end">
                                  {run?.prize_7}
                                  {run?.currency}
                                </td>
                                <td className="text-end">
                                  {run?.breeder_prize_7} {run?.currency}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </>
                    ),
                    confirm: {
                      cancel: {
                        label: "Kapat",
                      },
                    },
                  });
                }}
              />
            </div>
          </div>
        )}

        {isMobile && (
          <>
            <div className="d-flex mb-2" style={{ justifyContent: "space-between" }}>
              <button
                onClick={() => {
                  addAllToBetSlip(
                    Number(tjkBetSlipOptions.column),
                    run,
                    isAddedAll(Number(tjkBetSlipOptions.column), run) === false ? "add" : "remove"
                  );
                }}
                className={
                  "btn btn-secondary fw-semibold fs-7 select-all-horse " +
                  (isAddedAll(Number(tjkBetSlipOptions.column), run) ? "selected" : "")
                }
              >
                H
              </button>
              {tjkBetSlipOptions.bettingSubType.label.split("-").length > 1 && (
                <button
                  onClick={() => {
                    addToTJKBetSlip(Number(tjkBetSlipOptions.column), run, "s", "toggle");
                  }}
                  className={
                    "btn btn-secondary fw-semibold ms-1 fs-7 select-all-horse " +
                    (isAdded(Number(tjkBetSlipOptions.column), run, "s") ? "selected" : "")
                  }
                >
                  S
                </button>
              )}
              <Link
                to={`${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}${
                  tjkBetSlipOptions.hippodrome._id
                }/hazir-kuponlar`}
                className="btn btn-muhtemeller fw-semibold w-50 fs-7 ms-1 lh-sm"
              >
                HAZIR KUPON
              </Link>
              <Link
                to={`${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}${
                  tjkBetSlipOptions.hippodrome._id
                }/yorumlar`}
                className={`btn btn-muhtemeller fw-semibold w-25 fs-7 ms-1`}
              >
                YORUMLAR
              </Link>
              {/*<Link*/}
              {/*    to={`${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}${tjkBetSlipOptions.hippodrome._id}/tjk-tv`}*/}
              {/*    className={"btn btn-secondary fw-semibold fs-7 select-all-horse ms-1 btn-muhtemeller w-25"}>*/}
              {/*    <img alt={"videoIcon"} src={videoIcon} width={21} className={"me-1"}/> TV*/}
              {/*</Link>*/}

              <Link
                to={`${paths.statistics.url}/${
                  tjkBetSlipOptions.hippodrome.date.split("T")[0]
                }/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}`}
                className={`btn btn-sm btn-muhtemeller ms-1 w-auto`}
              >
                <img src={statisticsIcon} className={"invert-color"} width={21} />
              </Link>

              <div className="dropdown dropdown-without-arrow">
                <button
                  className={`btn btn-sm btn-secondary dropdown-toggle ms-1`}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="24"
                    fill="currentColor"
                    className="me-1"
                    viewBox="0 -2 10 20"
                  >
                    <path d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                  </svg>
                </button>
                <ul className="dropdown-menu bg-white">
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => {
                        navigate(
                          `${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}${
                            tjkBetSlipOptions.hippodrome._id
                          }?sort=no`
                        );
                      }}
                    >
                      No'ya Göre
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => {
                        navigate(
                          `${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}${
                            tjkBetSlipOptions.hippodrome._id
                          }?sort=agf_ratio1`
                        );
                      }}
                    >
                      AGF'ye Göre
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => {
                        navigate(
                          `${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}${
                            tjkBetSlipOptions.hippodrome._id
                          }?sort=ganyan`
                        );
                      }}
                    >
                      Ganyan'a Göre
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => {
                        navigate(
                          `${paths.betting.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}${
                            tjkBetSlipOptions.hippodrome._id
                          }?sort=start`
                        );
                      }}
                    >
                      Kulvar'a Göre
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {_.orderBy(
              run?.horses,
              [
                (a: any) =>
                  sort != null
                    ? sort == "agf_ratio1"
                      ? a["agf_ratio1"]
                        ? Number(a["agf_ratio1"])
                        : Number(a["agf_ratio2"])
                      : Number(a[sort.toString()])
                    : Number(a.no),
              ],
              [sort === "agf_ratio1" ? "desc" : "asc"]
            ).map((horse: any, keyHorse: number) => {
              let horseIsAdded: any =
                isAdded(Number(tjkBetSlipOptions.column), run, horse) !== false ? "horse-added" : "";

              return (
                <div
                  key={`keyHorse-${keyHorse}`}
                  className={`mobile-betting-table ${horseIsAdded}`}
                  style={!horse.run_status ? { opacity: 0.4 } : {}}
                >
                  <div
                    className="horse-no"
                    onClick={() => {
                      addToTJKBetSlip(Number(tjkBetSlipOptions.column), run, horse, "toggle");
                    }}
                  >
                    {horse.run_status ? (
                      <>{horse.no}</>
                    ) : (
                      <>
                        <span className="fw-bold text-danger tooltip">
                          K<div className="tooltip-text">KOŞMAZ</div>
                        </span>
                      </>
                    )}
                  </div>

                  <div
                    className="horse-detail nowrap-ellipsis"
                    onClick={() => {
                      addToTJKBetSlip(Number(tjkBetSlipOptions.column), run, horse, "toggle");
                    }}
                  >
                    <div className={"d-flex align-items-center"}>
                      <div>
                        <img
                          src={`https://medya-cdn.tjk.org/formaftp/${horse.owner.code}.jpg`}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = jokeyIcon;
                          }}
                          width={24}
                          alt={horse.jokey.short_name}
                          className="jockey-uniform float-start "
                        />
                      </div>
                      <div className={"ms-2"}>
                        <GetStablemateIcon horse={horse} /> <b>{horse.name}</b>
                        <br />
                        {horse.weight || "-"}kg {horse.age ? horse.age : "-"}
                        {horse.jewelry && horse.jewelry !== "" && (
                          <span className="horse-jewelry text-danger">
                            / {horse.jewelry}
                            <div
                              className="text-dark"
                              dangerouslySetInnerHTML={{ __html: getPrettyFormatJewelry(horse.jewelry) }}
                            ></div>
                          </span>
                        )}
                      </div>
                    </div>

                    <div
                      style={
                        {
                          "--data-agf": `${Number(horse.agf_ratio1) ?? Number(horse.agf_ratio2)}%`,
                        } as React.CSSProperties
                      }
                      data-agf={Number(horse.agf_ratio1) ?? Number(horse.agf_ratio2)}
                      className={`mobile-agf-bar ${horse.agf_sort_no1 === 1 || horse.agf_sort_no2 === 1 ? "agf1" : ""}`}
                    >
                      <div className="bar"></div>
                      <div style={{ position: "relative" }}>
                        {horse.agf_ratio1 ? ` ${horse.agf_ratio1} (${horse.agf_sort_no1})` : null}
                        {horse.agf_ratio2 ? <> / {`${horse.agf_ratio2} (${horse.agf_sort_no2})`}</> : null}
                      </div>
                    </div>
                  </div>
                  <div
                    className="horse-other d-flex pe-2 justify-content-end align-items-center"
                    onClick={() => {
                      if (horse.code && Number(horse.code) > 0) {
                        setShowHorseStat(horse);
                      } else {
                        addToTJKBetSlip(Number(tjkBetSlipOptions.column), run, horse, "toggle");
                      }
                    }}
                  >
                    <div className={"w-100"} style={{ fontSize: 13 }}>
                      {horse.jokey.short_name}{" "}
                      {horse.apprentice && (
                        <>
                          <span className="text-danger fw-semibold">AP</span>
                        </>
                      )}
                      <br />
                      ST:{horse.start ? horse.start : "-"} KGS:{horse.kgs ? horse.kgs : "-"} HP:
                      {horse.handicap ? horse.handicap : "-"}
                      <br />
                      <div className={"d-flex justify-content-between"} style={{ marginTop: "5px" }}>
                        <div
                          className={
                            tjkBetSlipOptions.bettingType.id != 1881
                              ? `mobile-ganyan ${minGanyan === horse ? "minganyan" : ""}`
                              : "mobile-ganyan bg-body-secondary"
                          }
                        >
                          {tjkBetSlipOptions.bettingType.id === 1881 ? (
                            <>
                              {run.event_odds &&
                              run.event_odds[Number(horse.no)]?.win_odds &&
                              run.event_odds[Number(horse.no)]?.win_odds > 1 ? (
                                run.event_odds[Number(horse.no)]?.win_odds
                              ) : (
                                <img width={18} src={lockIcon} />
                              )}
                            </>
                          ) : (
                            <> G: {horse.ganyan || "-"}</>
                          )}
                        </div>
                        <span
                          style={{ lineHeight: "1.1rem" }}
                          dangerouslySetInnerHTML={{ __html: getPrettyFormatLastSix(horse.last6) }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}

        {!isMobile && (
          <table className="table betting-table table-borderless" style={{ tableLayout: "fixed" }}>
            <thead>
              <tr>
                <th style={{ width: "5%" }} className="text-center">
                  No
                </th>
                <th style={{ width: "18%" }}>Atın İsmi / Yaş</th>
                {selectedColumns.ganyan && <th className="">Ganyan</th>}

                {tjkBetSlipOptions.bettingType.id === 1881 && <th className="">Oran</th>}

                {selectedColumns.weight && <th className="">Kilo</th>}
                {selectedColumns.jockey && <th className="">Jokey</th>}
                {selectedColumns.origin && <th>Orijin</th>}
                {selectedColumns.owner && (
                  <th className="tooltip">
                    S/A<span className="tooltip-text">Sahibi / Antrenörü</span>
                  </th>
                )}
                {selectedColumns.start && (
                  <th className="tooltip">
                    St<span className="tooltip-text">Start</span>
                  </th>
                )}
                {selectedColumns.handicap && (
                  <th className="tooltip">
                    Hp<span className="tooltip-text">Handikap</span>
                  </th>
                )}
                {selectedColumns.lastSix && (
                  <th className="tooltip">
                    Son 6<span className="tooltip-text">Son 6 koşusu Sonuçları</span>
                  </th>
                )}
                {selectedColumns.lastTwenty && (
                  <th className="tooltip">
                    S20<span className="tooltip-text">Kalan Koşu Sayısı</span>
                  </th>
                )}
                {selectedColumns.kgs && (
                  <th className="tooltip">
                    KGS<span className="tooltip-text">Koşmadığı Gün Sayısı</span>
                  </th>
                )}
                {selectedColumns.eid && (
                  <th className="tooltip">
                    EİD<span className="tooltip-text">En iyi Derecesi</span>
                  </th>
                )}

                {selectedColumns.traning && <th className="">İdman</th>}
                {selectedColumns.agf && <th className="">AGF(%)</th>}
              </tr>
            </thead>
            <tbody>
              {_.orderBy(
                run?.horses,
                [
                  (a: any) =>
                    sort != null
                      ? sort == "agf_ratio1"
                        ? a["agf_ratio1"]
                          ? Number(a["agf_ratio1"])
                          : Number(a["agf_ratio2"])
                        : Number(a[sort.toString()])
                      : Number(a.no),
                ],
                [sort === "agf_ratio1" ? "desc" : "asc"]
              ).map((horse: any, keyHorse: number) => {
                let horseIsAdded: any =
                  isAdded(Number(tjkBetSlipOptions.column), run, horse) !== false ? "horse-added" : "";

                return (
                  <React.Fragment key={`DataTable-horses-${keyHorse}`}>
                    <tr>
                      <th className="seperator"></th>
                    </tr>
                    <tr
                      key={`Tjk-races-${keyHorse}`}
                      style={!horse.run_status ? { opacity: 0.4 } : {}}
                      className={`${horseIsAdded}`}
                      onClick={(e: any) => {
                        if (e.target.nodeName === "TD") {
                          addToTJKBetSlip(Number(tjkBetSlipOptions.column), run, horse, "toggle");
                        }
                      }}
                    >
                      <td style={{ width: "28px" }}>
                        <div
                          className="add-to-betslip"
                          onClick={() => {
                            addToTJKBetSlip(Number(tjkBetSlipOptions.column), run, horse, "toggle");
                          }}
                        >
                          {horse.run_status ? (
                            <>{horse.no}</>
                          ) : (
                            <span className="fw-bold text-danger tooltip">
                              K<div className="tooltip-text">KOŞMAZ</div>
                            </span>
                          )}
                        </div>
                      </td>
                      <td>
                        <img
                          src={`https://medya-cdn.tjk.org/formaftp/${horse.owner.code}.jpg`}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = jokeyIcon;
                          }}
                          width={24}
                          alt={horse.jokey.short_name}
                          className="jockey-uniform float-start "
                        />
                        <span
                          className={`d-block fw-bold nowrap-ellipsis tooltip ${horse.code ? "cursor-pointer" : ""}`}
                          onClick={() => {
                            if (horse.code && Number(horse.code) > 0) {
                              setShowHorseStat(horse);
                            }
                          }}
                        >
                          <GetStablemateIcon horse={horse} /> {horse.name}
                          <div className="tooltip-text">{horse.name}</div>
                        </span>
                        {horse.age ? horse.age : "-"}{" "}
                        {horse.jewelry && horse.jewelry !== "" && (
                          <span className="horse-jewelry text-danger">
                            / {horse.jewelry}
                            <div
                              className="text-dark"
                              dangerouslySetInnerHTML={{ __html: getPrettyFormatJewelry(horse.jewelry) }}
                            ></div>
                          </span>
                        )}
                      </td>
                      {selectedColumns.ganyan && (
                        <td className={`${minGanyan === horse ? "text-success fw-bold" : ""}`}>
                          {horse.ganyan || "-"}
                        </td>
                      )}

                      {tjkBetSlipOptions.bettingType.id === 1881 && (
                        <td className={`${minGanyan === horse ? "text-success fw-bold" : ""}`}>
                          {run.event_odds &&
                          run.event_odds[Number(horse.no)]?.win_odds &&
                          run.event_odds[Number(horse.no)]?.win_odds > 1 ? (
                            run.event_odds[Number(horse.no)]?.win_odds
                          ) : (
                            <img width={18} src={lockIcon} />
                          )}
                        </td>
                      )}

                      {selectedColumns.weight && (
                        <td className="text-nowrap">
                          {horse.weight || "-"}
                          {Number(horse.extra_weight) > 0 && (
                            <sup className={"text-danger tooltip"}>
                              +{horse.extra_weight}
                              <span className="tooltip-text">Fazla Kilo</span>
                            </sup>
                          )}
                        </td>
                      )}

                      {selectedColumns.jockey && (
                        <td className="fw-medium">
                          {horse.owner ? (
                            <div>
                              <div
                                style={{ paddingTop: horse.apprentice ? 0 : 7 }}
                                className={`d-block nowrap-ellipsis tooltip ${
                                  horse?.jokey?.code ? "cursor-pointer" : ""
                                }`}
                                onClick={() => {
                                  if (horse.jokey && Number(horse.jokey.code) > 0) {
                                    setShowJockeyStat(horse);
                                  }
                                }}
                              >
                                {horse.jokey.short_name}
                                <span className={"tooltip-text"}>{horse.jokey.name}</span>
                                {horse.apprentice && (
                                  <>
                                    <br />
                                    <span className="text-danger fw-semibold">AP</span>
                                  </>
                                )}
                              </div>
                            </div>
                          ) : (
                            "-"
                          )}
                        </td>
                      )}
                      {selectedColumns.origin && (
                        <td className="fw-medium">
                          <span className="d-block origin-father nowrap-ellipsis tooltip">
                            {horse.father} <span className={"tooltip-text"}>{horse.father}</span>{" "}
                          </span>
                          <span className="origin-mother nowrap-ellipsis  d-block tooltip">
                            {horse.mother} <span className={"tooltip-text"}>{horse.mother}</span>
                          </span>
                        </td>
                      )}
                      {selectedColumns.owner && (
                        <td className="">
                          <span className="owner nowrap-ellipsis tooltip d-block">
                            {horse.owner ? horse.owner.name : "-"}{" "}
                            <span className={"tooltip-text"}>{horse.owner ? horse.owner.name : "-"}</span>
                          </span>
                          <span className="coach nowrap-ellipsis tooltip d-block ">
                            {horse.trainer ? horse.trainer.name : "-"}
                            <span className={"tooltip-text"}>{horse.trainer ? horse.trainer.name : "-"}</span>
                          </span>
                        </td>
                      )}
                      {selectedColumns.start && <td className="">{horse.start ? horse.start : "-"}</td>}
                      {selectedColumns.handicap && <td className="">{horse.handicap ? horse.handicap : "-"} </td>}
                      {selectedColumns.lastSix && (
                        <td className="" dangerouslySetInnerHTML={{ __html: getPrettyFormatLastSix(horse.last6) }}></td>
                      )}
                      {selectedColumns.lastTwenty && (
                        <td
                          className=""
                          dangerouslySetInnerHTML={{ __html: getPrettyFormatLastSix(horse.last20) }}
                        ></td>
                      )}
                      {selectedColumns.kgs && <td className="">{horse.kgs ? horse.kgs : "-"} </td>}
                      {selectedColumns.eid && (
                        <td className="">
                          <div className="tooltip">
                            {horse.best_time || "-"}
                            <div className="tooltip-text">{horse.best_time_description}</div>
                          </div>
                        </td>
                      )}

                      {selectedColumns.traning && (
                        <td className="">
                          {horse.training_video ? (
                            <>
                              <a
                                onClick={() => {
                                  let urlParts = horse.training_video.split("/");
                                  urlParts[6] = Number(urlParts[6]);
                                  horse.training_video = urlParts.join("/");
                                  setShowTrainingVideo(horse);
                                }}
                              >
                                <img alt={"iconPlayCircle"} className="" src={iconPlayCircle} width={24} />
                              </a>
                            </>
                          ) : (
                            "-"
                          )}
                        </td>
                      )}
                      {selectedColumns.agf && (
                        <td
                          className={`${
                            horse.agf_sort_no1 === 1 || horse.agf_sort_no2 === 1 ? "text-success fw-bold" : ""
                          }`}
                        >
                          {horse.agf_ratio1 ? `${horse.agf_ratio1} (${horse.agf_sort_no1})` : null}
                          {horse.agf_ratio2 ? (
                            <>
                              {" "}
                              {horse.agf_ratio1 ? <br /> : ""} {`${horse.agf_ratio2} (${horse.agf_sort_no2})`}
                            </>
                          ) : null}
                        </td>
                      )}
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        )}

        <Modal
          isOpen={showJockeyStat !== false}
          onRequestClose={() => {
            setShowJockeyStat(false);
          }}
          ariaHideApp={false}
          onAfterOpen={getContentJockey}
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              width: "1140px",
              maxHeight: "calc(100% - 40px)",
              marginRight: "-50%",
              maxWidth: " calc( 100% - 40px )",
              padding: "15px",
              transform: "translate(-50%, -50%)",
              background: "var(--bs-white)",
              borderRadius: "var(--bs-border-radius-xl)",
              border: 0,
            },
            overlay: {
              zIndex: 2,
              backgroundColor: "rgba(0, 0, 0, 0.25)",
              backdropFilter: "blur(10px)",
            },
          }}
          contentLabel=""
        >
          <div className="card mt-0">
            <div className="title modal-sticky-title">
              <div className={"float-end"}>
                <img
                  alt={"iconExit"}
                  src={iconExit}
                  width={24}
                  className={"float-end cursor-pointer"}
                  onClick={() => setShowJockeyStat(false)}
                />
              </div>
              <h3>{showJockeyStat.jokey && showJockeyStat.jokey.name}</h3>
            </div>
            <div className="body">
              <div className="tjkIframe" id="id_iframe">
                <p className="p-5 text-center">Yükleniyor.</p>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={showHorseStat !== false}
          onRequestClose={() => {
            setShowHorseStat(false);
          }}
          onAfterOpen={getContentHorse}
          ariaHideApp={false}
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              width: "1140px",
              maxHeight: "calc(100% - 40px)",
              marginRight: "-50%",
              maxWidth: " calc( 100% - 40px )",
              padding: "15px",
              transform: "translate(-50%, -50%)",
              background: "var(--bs-white)",
              borderRadius: "var(--bs-border-radius-xl)",
              border: 0,
            },
            overlay: {
              zIndex: 2,
              backgroundColor: "rgba(0, 0, 0, 0.25)",
              backdropFilter: "blur(10px)",
            },
          }}
          contentLabel=""
        >
          <div className="card mt-0">
            <div className="title modal-sticky-title">
              <div className={"float-end"}>
                <img
                  alt={"iconExit"}
                  src={iconExit}
                  width={24}
                  className={"float-end cursor-pointer"}
                  onClick={() => setShowHorseStat(false)}
                />
              </div>
              <h3>{showHorseStat.name}</h3>
            </div>
            <div className="body">
              <div className="tjkIframe" id="id_iframe">
                <p className="p-5 text-center">Yükleniyor.</p>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={showTrainingVideo !== false}
          onRequestClose={() => {
            setShowTrainingVideo(false);
          }}
          ariaHideApp={false}
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              width: "940px",
              maxHeight: "calc(100% - 40px)",
              marginRight: "-50%",
              maxWidth: " calc( 100% - 40px )",
              padding: "15px",
              transform: "translate(-50%, -50%)",
              background: "var(--bs-white)",
              borderRadius: "var(--bs-border-radius-xl)",
              border: 0,
            },
            overlay: {
              zIndex: 2,
              backgroundColor: "rgba(0, 0, 0, 0.25)",
              backdropFilter: "blur(10px)",
            },
          }}
          contentLabel=""
        >
          <div className={"float-right  me-n2 mt-n2 mb-1"}>
            <img
              alt={"iconExit"}
              src={iconExit}
              width={24}
              className={"float-end cursor-pointer"}
              onClick={() => setShowTrainingVideo(false)}
            />
          </div>

          <video controls autoPlay style={{ width: "100%" }}>
            <source
              src={showTrainingVideo && showTrainingVideo.training_video ? showTrainingVideo.training_video : null}
              type="video/mp4"
            />
          </video>
        </Modal>

        <Modal
          isOpen={openCustomizeModal}
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              width: "33%",
              maxHeight: "calc(100% - 40px)",
              marginRight: "-50%",
              maxWidth: " calc( 100% - 40px )",
              padding: "15px",
              transform: "translate(-50%, -50%)",
              background: "var(--bs-white)",
              borderRadius: "var(--bs-border-radius-xl)",
              border: 0,
            },
            overlay: {
              zIndex: 2,
              backgroundColor: "rgba(0, 0, 0, 0.25)",
              backdropFilter: "blur(10px)",
            },
          }}
          onRequestClose={() => {
            Storage.set(`selectedColumns`, selectedColumns);
            setOpenCustomizeModal(false);
          }}
          contentLabel=""
        >
          <div className="card mt-0">
            <div className="title modal-sticky-title">
              <div className={"float-end"}>
                <img
                  alt={"iconExit"}
                  src={iconExit}
                  width={24}
                  className={"float-end cursor-pointer"}
                  onClick={() => {
                    Storage.set(`selectedColumns`, selectedColumns);
                    setOpenCustomizeModal(false);
                  }}
                />
              </div>
              <h3>Kişiselleştir</h3>
            </div>
            <div className="body">
              <ul className="customize-header">
                <li>
                  <div className="float-left">
                    <label className={"d-flex align-items-center"}>
                      <input
                        type="checkbox"
                        checked={selectedColumns.weight}
                        className={"customize-header-checkbox"}
                        onChange={() => {
                          selectedColumns.weight = !selectedColumns.weight;
                          console.log(selectedColumns);
                          forceUpdate();
                        }}
                      />{" "}
                      Kilo{" "}
                    </label>
                  </div>
                </li>

                <li>
                  <div className="float-left">
                    <label className={"d-flex align-items-center"}>
                      <input
                        type="checkbox"
                        checked={selectedColumns.jockey}
                        className={"customize-header-checkbox"}
                        onChange={() => {
                          selectedColumns.jockey = !selectedColumns.jockey;
                          forceUpdate();
                        }}
                      />{" "}
                      Jokey{" "}
                    </label>
                  </div>
                </li>
                <li>
                  <div className="float-left">
                    <label className={"d-flex align-items-center"}>
                      <input
                        type="checkbox"
                        checked={selectedColumns.origin}
                        className={"customize-header-checkbox"}
                        onChange={() => {
                          selectedColumns.origin = !selectedColumns.origin;
                          forceUpdate();
                        }}
                      />{" "}
                      Orijin{" "}
                    </label>
                  </div>
                </li>

                <li>
                  <div className="float-left">
                    <label className={"d-flex align-items-center"}>
                      <input
                        type="checkbox"
                        checked={selectedColumns.owner}
                        className={"customize-header-checkbox"}
                        onChange={() => {
                          selectedColumns.owner = !selectedColumns.owner;
                          forceUpdate();
                        }}
                      />{" "}
                      Sahibi / Antrenörü{" "}
                    </label>
                  </div>
                </li>
                <li>
                  <div className="float-left">
                    <label className={"d-flex align-items-center"}>
                      <input
                        type="checkbox"
                        checked={selectedColumns.start}
                        className={"customize-header-checkbox"}
                        onChange={() => {
                          selectedColumns.start = !selectedColumns.start;
                          forceUpdate();
                        }}
                      />{" "}
                      Start{" "}
                    </label>
                  </div>
                </li>
                <li>
                  <div className="float-left">
                    <label className={"d-flex align-items-center"}>
                      <input
                        type="checkbox"
                        checked={selectedColumns.handicap}
                        className={"customize-header-checkbox"}
                        onChange={() => {
                          selectedColumns.handicap = !selectedColumns.handicap;
                          forceUpdate();
                        }}
                      />{" "}
                      Handikap{" "}
                    </label>
                  </div>
                </li>
                <li>
                  <div className="float-left">
                    <label className={"d-flex align-items-center"}>
                      <input
                        type="checkbox"
                        checked={selectedColumns.lastSix}
                        className={"customize-header-checkbox"}
                        onChange={() => {
                          selectedColumns.lastSix = !selectedColumns.lastSix;
                          forceUpdate();
                        }}
                      />{" "}
                      Son 6 Koşu{" "}
                    </label>
                  </div>
                </li>
                <li>
                  <div className="float-left">
                    <label className={"d-flex align-items-center"}>
                      <input
                        type="checkbox"
                        checked={selectedColumns.lastTwenty}
                        className={"customize-header-checkbox"}
                        onChange={() => {
                          selectedColumns.lastTwenty = !selectedColumns.lastTwenty;
                          forceUpdate();
                        }}
                      />{" "}
                      S20{" "}
                    </label>
                  </div>
                </li>
                <li>
                  <div className="float-left">
                    <label className={"d-flex align-items-center"}>
                      <input
                        type="checkbox"
                        checked={selectedColumns.kgs}
                        className={"customize-header-checkbox"}
                        onChange={() => {
                          selectedColumns.kgs = !selectedColumns.kgs;
                          forceUpdate();
                        }}
                      />{" "}
                      Koşmadığı Gün Sayısı{" "}
                    </label>
                  </div>
                </li>
                <li>
                  <div className="float-left">
                    <label className={"d-flex align-items-center"}>
                      <input
                        type="checkbox"
                        checked={selectedColumns.eid}
                        className={"customize-header-checkbox"}
                        onChange={() => {
                          selectedColumns.eid = !selectedColumns.eid;
                          forceUpdate();
                        }}
                      />{" "}
                      En iyi Derece{" "}
                    </label>
                  </div>
                </li>
                <li>
                  <div className="float-left">
                    <label className={"d-flex align-items-center"}>
                      <input
                        type="checkbox"
                        checked={selectedColumns.ganyan}
                        className={"customize-header-checkbox"}
                        onChange={() => {
                          selectedColumns.ganyan = !selectedColumns.ganyan;
                          forceUpdate();
                        }}
                      />{" "}
                      Ganyan{" "}
                    </label>
                  </div>
                </li>
                <li>
                  <div className="float-left">
                    <label className={"d-flex align-items-center"}>
                      <input
                        type="checkbox"
                        checked={selectedColumns.traning}
                        className={"customize-header-checkbox"}
                        onChange={() => {
                          selectedColumns.traning = !selectedColumns.traning;
                          forceUpdate();
                        }}
                      />{" "}
                      İdman{" "}
                    </label>
                  </div>
                </li>
                <li>
                  <div className="float-left">
                    <label className={"d-flex align-items-center"}>
                      <input
                        type="checkbox"
                        checked={selectedColumns.agf}
                        className={"customize-header-checkbox"}
                        onChange={() => {
                          selectedColumns.agf = !selectedColumns.agf;
                          forceUpdate();
                        }}
                      />{" "}
                      AGF{" "}
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div className={"footer"}>
              <div className={"row"}>
                <div className={"col-6"}>
                  <button
                    onClick={() => {
                      Object.keys(defaultColumns).forEach((key: any) => {
                        selectedColumns[key] = defaultColumns[key];
                      });
                      Storage.set(`selectedColumns`, { defaultColumns });
                      setOpenCustomizeModal(false);
                    }}
                    className="btn btn-secondary w-100"
                  >
                    Varsayılana Dön
                  </button>
                </div>
                <div className={"col-6"}>
                  <button
                    onClick={() => {
                      Storage.set(`selectedColumns`, selectedColumns);
                      setOpenCustomizeModal(false);
                    }}
                    className="btn btn-primary w-100"
                  >
                    Seçimlerimi Kaydet
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      {isMobile && tjkBetSlipOptions.bettingType.id !== 0 && (
        <MobileBetslip selectBettingColumn={selectBettingColumn}></MobileBetslip>
      )}
    </>
  );
}
