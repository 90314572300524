import {SupportMenu} from "./support";
import React, {useEffect} from "react";
import {ApiService} from "../../services/http.service";
import {env} from "../../constants/global.vars";
import {useNavigate, useParams} from "react-router";
import paths from "../../paths";
import {isMobile} from "../../App";

export function SupportContact(){

  const [subjects, setSubjects] = React.useState<any[]>([])
  const [issues, setIssues] = React.useState<any[]>([])
  const [hint, setHint] = React.useState<any>(null);
  const navigate = useNavigate();
  const params = useParams();
  const pathname = useNavigate();

  useEffect(()=>{
    let api = new ApiService();
    api.start('get', env.accounting_api + '/misc/api/list-questions/', null , false)
      .then((res:any)=>{
        if(params.id){
          getIssues(Number(params.id)).finally(()=>{
            setSubjects(res.data);
          })
        }else{
          setSubjects(res.data);
        }

      })
  },[])

  useEffect(()=>{
    if(!params.id){
      setIssues([]);
    }
  },[pathname]);

  const getIssues = async (e:any) => {
    if(Number(e) === -1){
      setIssues([]);
      setHint(null);
      return null;
    }
    let api = new ApiService();
    await api.start('post', env.accounting_api + '/misc/api/list-questions/', {'question_type':e} , false)
      .then((res:any)=>{
        setIssues(res.data);
        navigate( `${paths.support.url}/sikca-sorulan-sorular/${e}/`);
      })
  }

  return (<>
    <div className="page-widget dark p-4 support-wrapper">
      <div className="row">
        <div className={`col-lg-3 col-sm-12 ${isMobile ? '' : 'pt-3'}`}>
          <SupportMenu></SupportMenu>
        </div>
        <div className="col-lg-9 col-sm-12">

          <h1 className={`${isMobile ? 'mt-2' : ''}`}>İletişim</h1>

          <div className="page-widget">

            <h2 className="pb-1">Müşteri Hizmetleri</h2>
            <p className="px-3">
              4nala.com ile ilgili tüm soru ve sorunlarınızda bizimle iletişime geçebilirsiniz.
              Müşteri temsilcilerimiz size en kısa sürede dönüş yapacaktır.
            </p>


            <div className="p-3">

              <h3 className="fs-5 fw-bold mt-4">Müşteri Hizmetleri</h3>
              <p>
                <b>Çalışma saatleri;</b><br/>
                Hafta içi 09:00 - 18:00 - Hafta sonu 09:00 - 18:00 <br/>
                Destek Hattı: <a href="tel:08504416464" className="text-decoration-none text-dark fw-bold"> 0 850 441 64
                64 </a>

              </p>

              <h3 className="fs-5 fw-bold  mt-4">E-Posta Adresi</h3>
              <p>
                destek@4nala.com<br/>
              </p>

              <h3 className="fs-5 fw-bold  mt-4">Genel Müdürlük</h3>
              <p>
                Adres ; İnönü Mah. Cumhuriyet Cad. Park Apt. No 109 İç Kapı No; 8 Şişli/İstanbul <br/>
              </p>
              <p>
                <b>Kep Adresi: </b>jokersansoyunlari@hs01.kep.tr
              </p>

            </div>
          </div>
        </div>
      </div>
    </div>

  </>)
}
